export enum EField {
    temperature = 'temperature',
    humidity = 'humidity',
    pressure = 'pressure',
    battery = 'battery',
    rssi = 'rssi',
}

export enum EFieldExtra {
    seq = 'seq',
    accel = 'accel',
    accel_x = 'accel_x',
    accel_y = 'accel_y',
    accel_z = 'accel_z',
    movement_counter = 'movement_counter',
    tx_power = 'tx_power',
}

export enum EUnit {
    percent = 'percent',
    celsius = 'celsius',
    hpa = 'hpa',
    ms2 = 'ms2',
    dbm = 'dbm',
    volt = 'volt',
}

export enum EXRangeType {
    relative,
    absolute,
}

export enum ETimeUnit {
    s = 's',
    m = 'm',
    h = 'h',
    d = 'd',
    M = 'M',
    y = 'y',
}
