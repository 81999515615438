import { init } from 'echarts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EField } from './types/enums';
import { timeStr } from './utils/dateUtils';
import { baseLegend } from './utils/echartsUtils';
import { fieldColorMapping, unitLabel } from './utils/entityUtils';

import type { Device, Measurement } from './types/entities';
import type { EChartsOption, EChartsType, LegendComponentOption, LineSeriesOption } from 'echarts';

type Props = {
    devices: Device[];
    measurements: Measurement[];
};

export default function RssiChart({ devices, measurements }: Props) {
    const chartDomRef = useRef<HTMLDivElement>(null);
    const [chart, setChart] = useState<EChartsType | null>(null);
    const xLength = useMemo(() => 30000, []);

    const initialOption = useMemo<EChartsOption>(() => {
        const option: EChartsOption = {
            backgroundColor: 'transparent',
            grid: {
                left: '7%',
                right: '2%',
                top: '20%',
                bottom: '5%',
            },
            xAxis: {
                id: 'xAxis',
                type: 'time',
                animation: false,
                minInterval: 15000,
                position: 'top',
                axisTick: { length: 3 },
                axisLine: { lineStyle: { color: '#555' } },
                axisLabel: {
                    formatter: (value: number) => timeStr(new Date(value)),
                    fontSize: 11,
                    color: '#aaa',
                },
            },
            yAxis: {
                type: 'value',
                name: unitLabel.dbm,
                animation: false,
                nameGap: 25,
                axisLabel: { fontSize: 11 },
                splitLine: { lineStyle: { color: '#ffffff1a' } },
                interval: 20,
            },
        };

        return option;
    }, []);

    const set = useCallback(
        (option: EChartsOption) => {
            if (!chart) {
                console.error('Chart not initialized');
                return;
            }
            chart.setOption(option, undefined, true);
        },
        [chart],
    );

    // init chart
    // listen for reading events
    useEffect(() => {
        let _chart: EChartsType | null = null;

        if (chartDomRef.current) {
            _chart = init(chartDomRef.current, 'dark');
            _chart.setOption(initialOption);
            _chart.trigger('');
            setChart(_chart);
        }

        const onResize = () => _chart?.resize();
        window.addEventListener('resize', onResize);

        return () => {
            _chart?.dispose();
            window.removeEventListener('resize', onResize);
        };
    }, []);

    // update xAxis range on animation frame
    useEffect(() => {
        if (!chart) {
            return;
        }

        let rafId: number | null = null;

        const xShift = () => {
            const max = Date.now();
            const min = max - xLength;
            chart.setOption({ xAxis: { min, max } });
            rafId = requestAnimationFrame(xShift);
        };

        rafId = requestAnimationFrame(xShift);

        return () => {
            rafId && cancelAnimationFrame(rafId);
        };
    }, [chart]);

    // Update chart data when measurements change
    useEffect(() => {
        if (!chart) {
            return;
        }

        const legend: LegendComponentOption = {
            ...baseLegend,
            data: devices.filter(d => measurements.some(m => m.device.id === d.id)).map(d => d.display_name),
        };

        const series: LineSeriesOption[] = devices.map((d, di) => {
            const data: LineSeriesOption['data'] = measurements
                .filter(m => m.device.id === d.id)
                .map((m, mi, a) => {
                    if (mi === a.length - 1) {
                        return {
                            value: [m.time.getTime(), m.rssi],
                            label: {
                                show: true,
                                position: 'bottom',
                                fontSize: 11,
                            },
                            symbolSize: 5,
                        };
                    }
                    return [m.time.getTime(), m.rssi];
                });

            return {
                type: 'line',
                name: d.display_name,
                animation: false,
                data,
                lineStyle: {
                    width: 1,
                    color: fieldColorMapping[EField.rssi],
                    type: di === 0 ? 'dashed' : 'solid',
                },
                itemStyle: {
                    color: '#000',
                    borderColor: fieldColorMapping[EField.rssi],
                    borderWidth: 1,
                },
            };
        });

        set({ series, legend });
    }, [chart, measurements]);

    return <div ref={chartDomRef} style={{ width: '100%', aspectRatio: '16/10' }} />;
}
